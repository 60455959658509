import { Reducer, ReducerState, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {
  Button,
  ButtonGroup,
  DropdownButton,
  NavDropdown,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MNIDashboard, MyMNIPreferences } from '@/model/preferences/myMNIPreferences';
import { Context } from '@/App';

import {
  faBell,
  faCheck,
  faCircleExclamation,
  faGear,
  faRightFromBracket,
  faTableColumns,
  faUser,
  faVolumeHigh,
  faVolumeOff,
  faVolumeXmark,
} from '@fortawesome/free-solid-svg-icons';

import { Layouts, Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import DashboardViewControls from '@/components/dashboard/dashboard-view-controls';
import DashboardEditControls from '@/components/dashboard/dashboard-edit-controls';
import { useSearchParams } from 'react-router-dom';
import DashboardDeleteModal from '@/components/dashboard/dashboard-delete-modal';
import DashboardCreateModal from '@/components/dashboard/dashboard-create-modal';
import DashboardContent from '@/components/dashboard/dashboard-content';
import { NewsWidget } from '@/widgets/news-widget';
import { preferencesApi } from '@/api';
import { authService, localPreferencesService, themeService } from '@/services';
import { WidgetHostContext } from '@/widgets/widget-host/widget-host-context';
import SingleWidgetHost from '@/components/dashboard/single-widget-host';
import { Credentials } from '@/model/auth/credentials';
import useWidgetsFromLayouts from '@/components/dashboard/hooks/use-widgets-from-layouts';
import {
  DashboardAction,
  DashboardContentReducer,
  dashboardContentReducerWrapper,
  DashboardState,
} from '@/components/dashboard/store/dashboard-content-reducer';
import useDashboardPersistence from '@/components/dashboard/hooks/use-dashboard-persistence';
import dayjs from 'dayjs';
import useSubscription from '@/hooks/use-subscription';
import { WidgetNotification } from '@/widgets/notifications/widget-notification';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import useWidgetNotifications from '@/widgets/notifications/use-widget-notifications';
import { AppContext } from '@/model/app-context';
import { PreferenceConstants } from '@/api/preferences-api';
import Loading from '@/components/shared/loading';
import DashboardUserMenu from '@/components/dashboard/dashboard-user-menu';

const cols = { lg: 16, md: 12, sm: 8, xs: 4, xxs: 2 };

export default function Dashboard() {
  const [query, setQuery] = useSearchParams();
  const [initialized, setInitialized] = useState(false);
  const [showDeleteDashboardModal, setShowDeleteDashboardModal] = useState(false);
  const [showCreateDashboardModal, setShowCreateDashboardModal] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState<MNIDashboard>();
  const context = useContext<AppContext>(Context);

  const { notificationSoundOn, setNotificationSoundOn, notifications, acknowledgeNotification } =
    useWidgetNotifications();
  const activeWidgets = useWidgetsFromLayouts(selectedDashboard);

  const [state, dispatch]: [s: DashboardState, d: (a: DashboardAction) => void] =
    useReducer<DashboardContentReducer>(dashboardContentReducerWrapper, {
      lastLayout: {} as Layouts,
      loading: true,
      editing: false,
      dirty: false,
      highlightedWidgets: {},
      dashboards: [],
    } as ReducerState<DashboardContentReducer>);

  useDashboardPersistence({ state, dispatch });

  useEffect(() => {
    setSelectedDashboard(state.dashboards.find(value => value.id == state.selectedDashboard));
  }, [state.selectedDashboard, state.dashboards]);


  useEffect(() => {
    if (state.loading) return;

    const widgetId = query.get('widget');

    if (widgetId) {
      setTimeout(() => {
        addWidget(widgetId);
      }, 1000);

      query.delete('widget');
      setQuery(query);
    }
  }, [query, state]);

  useEffect(() => {
    if (initialized) return;

    localPreferencesService.getLocalPreferences().then(value => {
      themeService.setThemeBasedOnUserPreferences(value);
    });

    preferencesApi.getPreferences<MyMNIPreferences>(PreferenceConstants.MY_MNI).then(val => {
      dispatch({
        type: 'dashboardsLoaded',
        dashboards: val?.dashboard || [],
      });
    });

    setInitialized(true);

    return () => {
      localPreferencesService.getLocalPreferences().then((value: any) => {
        themeService.setThemeBasedOnUserPreferences(value);
      });
    };
  }, []);

  useSubscription<WidgetNotification>(
    MessageServiceConstants.WIDGET_NOTIFICATION_ACKNOWLEDGED,
    message => {
      message.widgetIds.forEach(value => {
        dispatch({ type: 'highlightWidget', id: value });
      });

      setTimeout(() => {
        message.widgetIds.forEach(value => {
          dispatch({ type: 'unHighlightWidget', id: value });
        });
      }, 3_000);
    },
  );

  const addWidget = (widgetId: string) => {

    if (!state.selectedDashboard) {
      dispatch({
        type: 'createDashboard',
        name: "My Dashboard",
        id: `${crypto.randomUUID()}-${new Date().getTime()}`,
      });
    }
    dispatch({
      type: 'addWidget',
      id: widgetId,
    });
  };

  const removeWidgetById = (widget: string) => {
    dispatch({
      type: 'removeWidget',
      id: widget,
    });
  };



  const onDashboardNameChange = (s: string) => {
    dispatch({
      type: 'setCurrentDashboardName',
      name: s,
    });
  };
  const onDashboardSelect = (value: MNIDashboard) => {
    dispatch({
      type: 'selectDashboard',
      id: value.id,
    });
    setShowCreateDashboardModal(false);
  };
  const createDashboard = (localDashboardName: string) => {
    dispatch({
      type: 'createDashboard',
      name: localDashboardName,
      id: `${crypto.randomUUID()}-${new Date().getTime()}`,
    });
    setShowCreateDashboardModal(false);
    dispatch({ type: 'editCurrentDashboard' });
  };
  const deleteSelectedDashboard = () => {
    dispatch({
      type: 'deleteCurrentDashboard',
    });
  };

  const Notifications = () => {
    return (
      <span>
        <ButtonGroup>
          <ToggleButtonGroup value={notificationSoundOn ? ['on'] : []} type={'checkbox'}>
            <ToggleButton
              size={'sm'}
              variant={notificationSoundOn ? 'success' : 'warning'}
              value={'on'}
              onClick={event => setNotificationSoundOn(!notificationSoundOn)}
            >
              {notificationSoundOn && <FontAwesomeIcon icon={faVolumeHigh} />}
              {!notificationSoundOn && <FontAwesomeIcon icon={faVolumeXmark} />}
            </ToggleButton>
          </ToggleButtonGroup>
          <DropdownButton
            as={ButtonGroup}
            className={''}
            size={'sm'}
            variant={'info'}
            disabled={notifications.length == 0}
            title={
              <>
                <span className={'fw-bold'}>
                  <FontAwesomeIcon icon={faBell} />
                  {notifications.length > 0 && (
                    <sup>
                      <span className={'badge bg-error'}>{notifications.length}</span>{' '}
                    </sup>
                  )}
                </span>
              </>
            }
            id="basic-nav-dropdown"
          >
            {notifications.length == 0 && (
              <NavDropdown.Item disabled={true}>No notifications</NavDropdown.Item>
            )}
            {notifications.map(value => (
              <NavDropdown.Item key={value.notification.correlationId + "-notification"} onClick={event => acknowledgeNotification(value)}>
                <div className={'d-flex align-items-center'}>
                  <div className={'me-3 h1 text-warning'}>
                    <FontAwesomeIcon icon={faCircleExclamation} />
                  </div>
                  <div>
                    <div>
                      <strong>{value.notification.title}</strong>
                    </div>
                    <div>
                      <small className={'text-muted'}>
                        {value.notification.description}
                        <div>
                          <small>
                            {dayjs(value.notification.date).format('h:mm a')} -{' '}
                            <span className={'text-body'}>
                              {dayjs(value.notification.date).fromNow()}
                            </span>
                          </small>
                        </div>
                      </small>
                    </div>
                  </div>
                </div>
              </NavDropdown.Item>
            ))}
          </DropdownButton>
        </ButtonGroup>
      </span>
    );
  };

  return (
    <>
      <WidgetHostContext.Provider
        value={{
          get activeWidgetIds(): string[] {
            return activeWidgets?.activeWidgets.map(value => value.id) || [];
          },
          removeWidgetById(id: string) {
            removeWidgetById(id);
          },
          addWidgetById(id: string) {
            addWidget(id);
            dispatch({ type: 'editCurrentDashboard' });
          },
          get hostId(): string {
            return 'dashboard'
          },
          get currentViewId(): string {
            return selectedDashboard?.id || 'default-view'
          }
        }}
      >
        <Context.Provider value={{ showMenu: false, localPreferences: context.localPreferences }}>
          <div className={'vh-100 d-block d-md-none'}>
            {activeWidgets && (
              <SingleWidgetHost
                activeWidgets={activeWidgets.activeWidgets}
                headerControls={<>
                  <Notifications />
                  <DashboardUserMenu className={' ms-1'} />
                </>}
              />
            )}
          </div>

          <div className="h-100 min-vh-100 dashboard-container d-none d-md-block">
            <nav className="navbar navbar-expand-sm navbar-dark sticky-top">
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  <img alt="logo" src={'/logo-white.svg'} height={'24'} />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  style={{ height: '40px', fontFamily: 'Inter' }}
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    {!state.loading && <div className={'d-flex '}>
                      <div className={'d-flex'}>
                        {state.selectedDashboard ? (
                          state.editing ? (
                            <>
                              <DashboardEditControls
                                dashboardName={selectedDashboard?.name || ''}
                                onDashboardNameChange={onDashboardNameChange}
                                onSaveDashboard={() => {
                                  dispatch({ type: 'saveCurrentDashboard' });
                                }}
                                onAddWidget={addWidget}
                                activeWidgets={activeWidgets?.activeWidgets || []}
                              />
                            </>
                          ) : (
                            <>
                              <DashboardViewControls
                                dashboards={state.dashboards || []}
                                selectedDashboard={selectedDashboard}
                                onDelete={() => {
                                  setShowDeleteDashboardModal(true);
                                }}
                                onEdit={() => {
                                  dispatch({ type: 'editCurrentDashboard' });
                                }}
                                onDashboardCreate={() => setShowCreateDashboardModal(true)}
                                onDashboardSelect={onDashboardSelect}
                              />
                            </>
                          )
                        ) : (
                          <button
                            className={'btn btn-outline-secondary me-2 btn-sm'}
                            style={{ fontFamily: 'Inter' }}
                            onClick={() => setShowCreateDashboardModal(true)}
                          >
                            <FontAwesomeIcon icon={faTableColumns} /> Create Dashboard
                          </button>
                        )}
                      </div>
                    </div>}
                  </ul>

                  <ul className="navbar-nav  mb-2 mb-lg-0 align-items-center">
                    <Notifications />

                    <DashboardUserMenu className={' ms-2'} />
                  </ul>
                </div>
              </div>
            </nav>

            <div>
              <div className={'container-fluid'}>
                {selectedDashboard && activeWidgets && (
                  <DashboardContent
                    dispatch={dispatch}
                    layouts={selectedDashboard.layouts}
                    editMode={state.editing}
                    activeWidgets={activeWidgets}
                    cols={cols}
                    highlightedWidgets={state.highlightedWidgets}
                  />
                )}

                {state.loading && (
                  <div className="text-center mt-5">

                    <Loading />
                  </div>
                )}
                {!state.selectedDashboard && !state.loading && (
                  <div className={'row'}>
                    <div className={'col-6 offset-3'}>
                      <div className={'alert alert-secondary m-5'}>
                        <h1>Welcome to MyMNI</h1>
                        <p>Dashboard not selected, please create one</p>{' '}
                        <Button size={'lg'} onClick={() => setShowCreateDashboardModal(true)}>
                          Create a Dashboard
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <DashboardCreateModal
                showCreateDashboardModal={showCreateDashboardModal}
                setShowCreateDashboardModal={setShowDeleteDashboardModal}
                onCreate={createDashboard}
              />

              <DashboardDeleteModal
                showDeleteDashboardModal={showDeleteDashboardModal}
                setShowDeleteDashboardModal={setShowDeleteDashboardModal}
                onConfirm={deleteSelectedDashboard}
              />

            </div>
          </div>
        </Context.Provider>
      </WidgetHostContext.Provider>
    </>
  );
}
