import { FormSelect } from 'react-bootstrap';
import { NewsWidget } from '@/widgets/news-widget';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import LocalWidgetRouter from '@/widgets/widget-router/local-widget-router';
import useWidgetFactory from '@/hooks/use-widget-factory';
import HasWidgetPermissions from '@/widgets/widget-permissions/has-widget-permissions';

export default function SingleWidgetHost({
  activeWidgets,
  headerControls,
}: {
  activeWidgets: NewsWidget[];
  headerControls: JSX.Element;
}) {
  const widgetFactory = useWidgetFactory();

  const inactiveWidgets = useMemo(() => {
    return widgetFactory
      .getStaticWidgetList()
      .filter(value => !activeWidgets.find(value1 => value1.id == value.id));
  }, [activeWidgets]);


  const getDefaultWidget = ()=>{
    const allWidgets = [...activeWidgets,...inactiveWidgets];

    const widgetId = localStorage.getItem("mobile-widget");
    if (widgetId) {
      const savedWidget = widgetFactory.getWidgetById(widgetId);

      if (savedWidget)
        return savedWidget;
    }

    return allWidgets[0];
  }

  const [selectedWidget, setSelectedWidget] = useState<NewsWidget>(
    getDefaultWidget()
  );

  const selectWidget = (event: ChangeEvent<HTMLSelectElement>) => {
    const widget =
      activeWidgets.find(value => value.id == event.target.value) ||
      inactiveWidgets.find(value => value.id == event.target.value);

    if (widget) setSelectedWidget(widget);
  };

  useEffect(() => {

    console.log("selected widget",selectedWidget.id)
    localStorage.setItem("mobile-widget",selectedWidget.id)
  }, [selectedWidget]);
  return (
    <div className={'d-flex h-100 flex-column p-1'}>
      <div className={'d-flex m-1'}>
        <div className={'flex-grow-1 flex-shrink-1 me-1'}>
          <FormSelect size={'sm'} className={'mb-1'} onChange={selectWidget} value={selectedWidget.id}>
            <optgroup label={'[selected]'}>
              {activeWidgets.map(value => (
                <option
                  value={value.id}
                  key={value.id + 'sel'}
                >
                  ⭐ {value.titleText}
                </option>
              ))}
            </optgroup>
            <optgroup label={'[other]'}>
              {inactiveWidgets.map(value => (
                <option
                  value={value.id}
                  key={value.id + 'sel'}
                >
                  {value.titleText}
                </option>
              ))}
            </optgroup>
          </FormSelect>
        </div>

        <span className={'flex-shrink-0 d-flex'}>{headerControls}</span>
      </div>

      <div className={'overflow-auto flex-grow-1 m-1'}>
        <HasWidgetPermissions widget={selectedWidget}>
          <LocalWidgetRouter widget={selectedWidget} key={selectedWidget.id} onNavigate={s => {}} />
        </HasWidgetPermissions>
      </div>
    </div>
  );
}
