import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Calendar } from '@/model/calendar/calendar';
import { selectApi } from '@/api';
import { NewsEvent } from '@/model/calendar/news-event';
import CountryFlag from '@/components/shared/country-flag';
import { CoverageBadges } from '@/components/data-calendar/coverage/coverage-badges';

export default function Events({ calendar }: { calendar: Calendar }) {
  const [events, setEvents] = useState<NewsEvent[]>();

  useEffect(() => {
    if (calendar) {
      selectApi.getEventsFromCalendarWithWindow(calendar.id, calendar.window).then(setEvents);
    }
  }, [calendar]);

  return (
    <>
      <div className={''}>
        {events && events.length > 0 && (
          <div className={' text-center border-bottom pb-3'}>
            <div className={'mb-3'}>
              <h3>
                <CountryFlag country={events[0].country} width={30} /> {events[0].title}
              </h3>
            </div>

            <div className={'row d-flex'}>
              <div className={'col-sm-4 offset-sm-2'}>
                <h4 className={'text-muted'}>Next Event</h4>

                <h1>{dayjs(events[0].date).format('MMM D')}</h1>
              </div>
              <div className={'col-sm-4 text-muted mt-auto mb-auto '}>
                <h3>{dayjs(events[0].date).fromNow()}</h3>
                <CoverageBadges event={events[0]} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={' overflow-auto'}>
        <div className={''}>
          <table className={'table table-sm small '}>
            <tbody>
              {events?.slice(1).map(value => (
                <tr key={value.id}>
                  <td className={'text-muted'}>{dayjs(value.date).format('MMM D')}</td>
                  <td>
                    <span className={'h-100 '}>
                      <CountryFlag country={value.country} width={20} />
                    </span>

                    <span className={'ms-1'}>{value.title}</span>
                  </td>
                  <td className={'text-end text-muted'}>{dayjs(value.date).fromNow()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
